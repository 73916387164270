<template>
    <v-container fluid class="py-0 px-0" ref="container">
        <v-tabs
            ref="tabs"
            active-class="white"
            background-color="grey lighten-3"
            hide-slider
        >
            <v-tab
                id="invoices"
                :to="{
                    name: 'invoices',
                    params: {
                        setting: setting,
                    },
                }"
            >
                INVOICES
            </v-tab>
            <v-tab
                v-if="approvalInternational"
                :to="{
                    name: 'international',
                    params: {
                        setting: setting,
                    },
                }"
            >
                INTERNATIONAL
            </v-tab>
            <v-tab
                v-if="generateInvoce"
                :to="{
                    name: 'generating',
                    params: {
                        setting: setting,
                    },
                }"
            >
                GENERATING
            </v-tab>
            <v-tab
                :to="{
                    name: 'invoicing-closed',
                    params: {
                        setting: setting,
                    },
                }"
            >
                CLOSED
            </v-tab>
        </v-tabs>
        <v-card flat>
            <v-card-text
                class="overflow-y-auto px-4 pt-4"
                :style="`height: ${height}px`"
            >
                <router-view />
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import SocketioService from '@/services/websocket/socket.service.js'

export default {
    name: 'Invoicing',
    data: () => ({
        loading: true,
        height: 0,
        companyId: JSON.parse(localStorage.getItem('company')),
        setting: {},
        generateInvoce: false,
        approvalInternational: false,
    }),
    async mounted() {
        try {
            SocketioService.connect({
                companyId: this.companyId,
            })
            this.onResize()
            const {
                data: { settings },
            } = await API.getSettings()
            const setting = settings.find(
                setting => setting.name.toLowerCase() == 'invoicing'
            )
            if (setting) {
                this.setting = setting
            }
            // set permissions
            const {
                data: { user },
            } = await API.getMyInfo()
            this.approvalInternational = user.permissions.includes(
                'approvalInternational'
            )
            this.generateInvoce = user.permissions.includes('generateInvoice')
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            setTimeout(() => {
                SocketioService.joinRoom(`${this.companyId}-INVOICE`)
            }, 3000)
        }
    },
    beforeDestroy() {
        SocketioService.leaveRoom(`${this.companyId}-INVOICE`)
        SocketioService.disconnect()
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        onResize() {
            const {
                container: { clientHeight: containerHeight },
                tabs: {
                    $el: { clientHeight: tabsHeight },
                },
            } = this.$refs
            this.height = window.innerHeight - containerHeight - tabsHeight - 25
        },
    },
}
</script>

<style>
.v-data-table {
    border: 1px solid #eeeeee;
}
thead {
    background: #eeeeee;
}
</style>
